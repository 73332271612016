import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { PageVue } from '@/fairplayer/primary/page';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { inject, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { LogoutVue } from '@/common/primary/auth/logout';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { loggerKey } from '@/common/domain/Logger';
import { Credential } from '@/common/domain/auth/Credential';

export default {
  name: 'EmailConfirmation',

  components: { ClubLogoVue, PageVue, ToastsListVue, FairplayerButtonVue, LogoutVue },

  props: {
    credential: {
      type: Object as PropType<Credential>,
      required: true,
    },
  },

  setup(props: any) {
    const { t } = useI18n();
    const router = useRouter();
    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;

    const twoFactorAuthenticationCode = ref('');
    const invalidTwoFactorAuthentication = ref(false);
    const isSendingTwoFactorAuthentication = ref(false);
    const isAskingNewTwoFactorAuthentication = ref(false);

    const loginFan = async () => {
      await authentication.loginWithCredential(props.credential);
      await router.push({ name: 'loginRedirectCallback' });
    };

    const goToLoginPage = async () => {
      await router.push({ name: 'login' });
    };

    const sendTwoFactorAuthentication = async () => {
      isSendingTwoFactorAuthentication.value = true;
      await authentication
        .confirmEmail(twoFactorAuthenticationCode.value, props.credential.email)
        .then(async () => {
          if (props.credential && props.credential.password !== '') {
            await loginFan();
          } else {
            await goToLoginPage();
          }
        })
        .catch(error => {
          invalidTwoFactorAuthentication.value = true;
          logger.error('Failed to confirm email', error);
        })
        .finally(() => (isSendingTwoFactorAuthentication.value = false));
    };

    const newTwoFactorAuthentication = async () => {
      isAskingNewTwoFactorAuthentication.value = true;
      authentication.resendConfirmationCode(props.credential.email).finally(() => (isAskingNewTwoFactorAuthentication.value = false));
    };

    return {
      newTwoFactorAuthentication,
      twoFactorAuthenticationCode,
      sendTwoFactorAuthentication,
      invalidTwoFactorAuthentication,
      isSendingTwoFactorAuthentication,
      isAskingNewTwoFactorAuthentication,
      t,
    };
  },
};
