PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-justify-center
        ClubLogoVue
      template(v-if='!exclusivesList.isLoading()')
        .fp-vertical-spacing--line(v-for='exclusivesListAlert in alertsKeys')
          ExclusivesListAlertVue(data-test='marketplace.alert' :alert-text='exclusivesListAlert')
        template(v-if='exclusivesList.value().length')
          .fp-vertical-spacing--line.-justify-end
            FairplayerButtonVue(icon='filter' :text='t("marketplace.filters")' noBorder data-test='open-filters' @click='openFiltersModal')
          .fp-vertical-spacing--line(v-if='hasFilters')
            ExclusivesListFiltersVue(:filters='currentFilters')
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols3.-cols2-up-md.-s-sm-up-sm
              .fp-responsive-grid--column(v-for='(exclusive, index) in exclusives' :key='index' data-test='exclusives')
                router-link.fp-link.-no-text-decoration(:to='{ name: \'exclusiveDetail\', params: { exclusiveSlug: exclusive.slug } }' :id='exclusive.id')
                  PresentationCardVue(:exclusive='exclusive' :data-test='`exclusive-${index}`')
        .fp-vertical-spacing--line(v-for='(linkedClub, index) in club.linkedClubs' :key='index')
          LinkedClubBannerVue(:club='linkedClub')
