import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawayCardVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/giveaway-card';
import { ExclusiveDetailHeadingVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-detail-heading';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { fromMedia } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { MediaType } from '@/common/domain/MediaType';
import { ExclusiveMediaVue } from '@/common/primary/exclusive-media';
import { ExclusiveAuctionVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction';
import { FairplayerVideoVue } from '@/common/primary/fairplayer-video';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'ExclusiveDetail',

  components: {
    FairplayerButtonVue,
    GiveawayCardVue,
    ExclusiveAuctionVue,
    ExclusiveDetailHeadingVue,
    ExclusiveMediaVue,
    FairplayerVideoVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  emits: ['add-bid'],

  setup(props, { emit }) {
    const { d, t } = useI18n();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const club = fromClub(clubRepository.getCurrentClub()!);

    const selectedMedia = ref(0);

    watch(
      () => props.exclusive,
      (newValue, oldValue) => {
        if (newValue.id !== oldValue.id) {
          selectedMedia.value = 0;
        }
      }
    );

    const medias = computed(() => [{ url: props.exclusive.imageUrl, type: MediaType.IMAGE }, ...props.exclusive.medias.map(fromMedia)]);

    const goBack = async () => {
      if (club.isFoundation) {
        await goToDonationPage();
      } else {
        await goToExclusiveList();
      }
    };
    const goToExclusiveList = async () => await router.push({ name: 'marketplace' });
    const goToDonationPage = async () => await router.push({ name: 'donationPage' });

    const selectMedia = (index: number) => {
      selectedMedia.value = index;
    };

    const addBid = () => emit('add-bid');

    const highlightMedia = () => {
      highlightedMediaBus.open({
        medias: medias.value,
        activeIndex: selectedMedia.value,
      });
    };

    return {
      d,
      t,
      addBid,
      selectedMedia,
      medias,
      selectMedia,
      goBack,
      highlightMedia,
    };
  },
});
