import { computed, inject, onMounted, onUnmounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/fairplayer/primary/page';
import { euro } from '@/common/domain/token/Fiat';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { PresentationCardVue } from '@/common/primary/presentation-card';
import { useRoute } from 'vue-router';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { LoginVue } from '@/common/primary/auth/login';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { AuctionUi } from '@/fairplayer/primary/marketplace/Auction.ui';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';
import { LiveAuctionFooterVue } from '@/fairplayer/primary/donation-page/live-auction-footer';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { LostAuctionsVue } from '@/fairplayer/primary/donation-page/lost-auctions';

export default {
  name: 'DonationPage',

  components: {
    ExclusivePriceVue,
    FairplayerButtonVue,
    FairplayerImageVue,
    LostAuctionsVue,
    LiveAuctionFooterVue,
    LoginVue,
    PageVue,
    PresentationCardVue,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const exclusives = ref(Loader.loading<ExclusiveUi[]>());
    const amount: Ref<number | undefined> = ref();
    const isCreatingOneTimeDonation = ref(false);
    const refreshExclusivesIntervalId = ref(0);

    const isAuthenticated = ref(Loader.loading<boolean>());
    const currentExclusives = computed(() => {
      const withEndDate = exclusives.value
        .value()
        .filter(exclusive => exclusive.auction && exclusive.auction.endDate > new Date())
        .sort((a, b) => a.auction!.endDate!.getTime() - b.auction!.endDate!.getTime());

      const withoutEndDate = exclusives.value.value().filter(exclusive => !exclusive.auction);

      return [...withEndDate, ...withoutEndDate];
    });

    const liveAuction = computed<AuctionUi | undefined>(() => {
      return exclusives.value
        .value()
        .map(exclusive => exclusive.auction)
        .find(auction => auction?.status === AuctionStatus.ACTIVE);
    });

    const passedExclusives = computed(() =>
      exclusives.value.value().filter(exclusive => exclusive.auction && exclusive.auction.endDate < new Date())
    );

    const paymentFailed = computed(() => route.query['payment-result'] === 'FAILURE');

    const createDonation = async () => {
      isCreatingOneTimeDonation.value = true;
      const clubSlug = clubRepository.getCurrentSlug();
      const currentLocation = globalWindow.location.href.split('?')[0];
      donationRepository
        .create({ clubSlug, amount: euro(+amount.value!), currentLocation })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch((error: any) => {
          logger.error('Failed to create donation', error);
          isCreatingOneTimeDonation.value = false;
        });
    };

    const autoRefreshExclusives = (exclusives: ExclusiveUi[]) => {
      if (refreshExclusivesIntervalId.value || !exclusives.length) {
        return;
      }
      refreshExclusivesIntervalId.value = globalWindow.setInterval(loadExclusives, 10000);
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        let fanId = undefined;
        if (isAuthenticated.value.value()) {
          fanId = (await fanRepository.getForClub(clubRepository.getCurrentClub())).id;
        }
        const exclusivesResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        const mappedExclusives = exclusivesResponse
          .filter(exclusive => exclusive.stock > 0)
          .map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub(), fanId));
        autoRefreshExclusives(mappedExclusives);
        exclusives.value.loaded(mappedExclusives);
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value.loaded([]);
      }
    };

    onMounted(async () => {
      await authentication.isAuthenticated().then(authenticated => {
        isAuthenticated.value.loaded(authenticated);
      });

      return loadExclusives();
    });

    onUnmounted(() => {
      if (refreshExclusivesIntervalId.value) {
        globalWindow.clearInterval(refreshExclusivesIntervalId.value);
      }
    });

    return {
      liveAuction,
      paymentFailed,
      passedExclusives,
      currentExclusives,
      exclusives,
      amount,
      createDonation,
      isAuthenticated,
      isCreatingOneTimeDonation,
      t,
    };
  },
};
