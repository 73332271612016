import { defineComponent, PropType, ref } from 'vue';
import { PresentationCardVue } from '@/common/primary/presentation-card';
import { CarouselVue } from '@/common/primary/carousel';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';

export default defineComponent({
  name: 'ExclusivesCarousel',

  components: { PresentationCardVue, CarouselVue },

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup() {
    const currentIndex = ref(0);

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index;
    };

    return {
      setCurrentIndex,
      currentIndex,
    };
  },
});
