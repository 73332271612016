include exclusive-page.loading.mixin.pug

PageVue
  template(v-slot:body)
    NotFoundVue(v-if='displayExclusiveError' :not-found-ui='exclusiveNotFoundUi()')
    template(v-if='exclusiveUi.isLoading()')
      +exclusive-page-loading
    template(v-if='!exclusiveUi.isLoading()')
      .fp-vertical-spacing#exclusive-detail(v-if='exclusiveUi.value().visible')
        .fp-vertical-spacing--line
          ExclusiveDetailVue(:exclusive='exclusiveUi.value()' @add-bid='addBid')
        .fp-vertical-spacing--line
          RecommendedExclusivesVue(:current-exclusive='exclusiveUi.value()')
        .fp-vertical-spacing--line(v-for='(linkedClub, index) in club.linkedClubs' :key='index')
          LinkedClubBannerVue(:club='linkedClub')
      HiddenExclusiveVue(v-if='!exclusiveUi.value().visible' :exclusive='exclusiveUi.value()')
  template(v-if='!isAuthenticated.isLoading() && !exclusiveUi.isLoading() && !walletUi.isLoading()' v-slot:footer)
    ExclusivePageFooterVue(:exclusive-ui='exclusiveUi.value()' :is-buying='isBuying' :is-authenticated='isAuthenticated.value()' :auction-payment-due='auctionPaymentDue' @buy='buy(false)')
