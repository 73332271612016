include ../../../common/primary/icon/icon.mixin.pug

PageVue(v-if='partnerSlug && !partner')
  template(v-slot:body)
    NotFoundVue(:not-found-ui='partnerNotFoundUi()')
.fp-banner-with-logo.-scrollable(v-else)
  .fp-banner-with-logo--background
    img(:src='club.bannerUrl' :alt='t("logo.alt.banner")')
  .fp-banner-with-logo--logo
    ClubLogoVue(v-if='!partner' lg)
    .fp-double-logo(v-else)
      .fp-double-logo--main
        ClubLogoVue
      .fp-double-logo--secondary
        .fp-logo.-sm
          img(:src='partner.logo.url' :alt='t("logo.alt.partner")')
  .fp-banner-with-logo--content
    .fp-vertical-spacing
      template(v-if='!partner')
        .fp-vertical-spacing--line.-align-left.-align-center-up-md
          span.fp-text.-text-lg.-text-md-up-lg {{ t('giveaway.earn') }}
        .fp-vertical-spacing--line.-align-left.-align-center-up-md
          span.fp-text.-text-lg.-text-md-up-lg {{ t('giveaway.manual') }}
      template(v-else)
        .fp-vertical-spacing--line.-align-left.-align-center-up-md
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              span.fp-text.-text-3xl.-text-lg-up-lg.-text-semi-bold {{ t('giveaway.partner.registration') }}
            .fp-vertical-spacing--line
              span.fp-text.-text-8xl.-text-xl-up-lg.-text-semi-bold {{ partner.name }}
        .fp-vertical-spacing--line.-align-left.-align-center-up-md
          span.fp-text.-text-lg.-text-md-up-lg(data-test='partner-earn-text') {{ t('giveaway.partner.earn', {partner: partner.name}) }}
        .fp-vertical-spacing--line.-align-left.-align-center-up-md
          span.fp-text.-text-lg.-text-md-up-lg {{ t('giveaway.manual') }}
      .fp-vertical-spacing--line.-justify-center-up-lg.-justify-left
        GiveawayParticipationFormVue
      .fp-vertical-spacing--line.-align-center-up-md.-align-left
        span.fp-text.-text-md.-text-md-up-lg.-text-semi-bold {{ t('giveaway.tokenTitle') }}
      .fp-vertical-spacing--line.-align-center-up-md.-align-left
        span.fp-text.-text-md.-text-md-up-lg {{ t('giveaway.tokenDescription') }}
      .fp-vertical-spacing--line.-justify-center-up-lg.-justify-left
        FairplayerButtonVue(data-test='go-to-marketplace' icon='shopping-cart' :text='t(\'giveaway.marketplace\')' @click='goToMarketplace')
