import { Member } from '@/fairplayer/domain/member/Member';

export interface MemberUi {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  me: boolean;
}

export const fromMember = (member: Member, me: boolean): MemberUi => ({
  id: member.id,
  pictureUrl: member.pictureUrl,
  firstName: member.firstName,
  lastName: member.lastName,
  phoneNumber: member.phoneNumber,
  me,
});
