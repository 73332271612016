include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    NotFoundVue(v-if='displayProgramNotFound' :not-found-ui='programNotFoundUi()')
    .fp-vertical-spacing.-s-xl(v-if='!programUi.isLoading()')
      .fp-vertical-spacing--line(data-test='program')
        .fp-program-detail
          .fp-program-detail--image
            .fp-display
              .fp-display--image(data-test='image')
                img(:src='programUi.value().imageUrl' :alt='programUi.value().name')
              .fp-display--left-action(@click='goToPrograms' data-test='go-to-programs')
                +fp-icon('arrow-left', {size: '16'})
          .fp-program-detail--info
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line
                h1.fp-title(data-test='name') {{ programUi.value().name }}
              .fp-vertical-spacing--line
                span.fp-text(v-html='programUi.value().description.html' data-test='description')
      .fp-vertical-spacing--line(v-if='!postList.isLoading() && postList.value().length > 0')
        ProgramPostsListVue(:posts='postList.value()')
      .fp-vertical-spacing--line(v-if='!programs.isLoading()')
        OtherProgramsVue(:other-programs='otherPrograms')
