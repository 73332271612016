include ../../../common/primary/icon/icon.mixin.pug

.fp-presentation-card
  .fp-presentation-card--image
    FairplayerImageVue(:url='exclusive.imageUrl')
    PresentationCardAuctionVue(v-if='exclusive.auction' :auction='exclusive.auction')
  .fp-presentation-card--title(:title='exclusive.name') {{ exclusive.name }}
  .fp-presentation-card--line
    ExclusivePriceVue(v-if='exclusive.auction' :pricing='exclusive.auction.currentPrice' data-test='auction-pricing')
    ExclusivePriceVue(v-else :pricing='exclusive.pricing' data-test='pricing')
  .fp-presentation-card--mask(v-if='maskText')
    .fp-badge(data-test='mask-text') {{ maskText }}
