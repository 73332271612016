include ../../../common/primary/icon/icon.mixin.pug

.fp-layout(v-if='!club.isLoading()')
  .fp-layout--top-menu(v-if='!club.isLoading() && !isAuthenticated.isLoading() && !authenticationRequired && menuItems.length > 0')
    TopMenuVue(:menu-items='menuItems' :is-authenticated='isAuthenticated.value()')
  .fp-layout--announcement
    AnnouncementVue(v-if='isArchived' :announcement-ui='archivedAnnouncementUi' data-test='archived-announcement')
    AnnouncementVue(v-if='isDevEnvironment && displayDevEnvironmentAnnouncement' :announcement-ui='devEnvironmentAnnouncementUi' @close='hideDevAnnouncement' data-test='dev-announcement')
    AnnouncementVue(v-if='paymentsDueAnnouncementUi' :announcement-ui='paymentsDueAnnouncementUi' data-test='payments-due-announcement')
  .fp-layout--body
    router-view(v-if='!displayClubError')
    PageVue(v-if='displayClubError')
      template(v-slot:body)
        NotFoundVue(:not-found-ui='clubNotFoundUi()')
  .fp-layout--bottom-menu(v-if='!isAuthenticated.isLoading() && !authenticationRequired && menuItems.length > 0')
    HorizontalMenuVue(:menu-items='menuItems' :is-authenticated='isAuthenticated.value()')
  .fp-layout--toaster
    ToastsListVue
  BottomModalVue
  ModalVue
  HighlightedMediaVue
PrinterVue
