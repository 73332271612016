import { defineAsyncComponent, shallowRef } from 'vue';
import { ComponentStep } from '@/common/domain/Step';

export const kycSteps: ComponentStep[] = [
  {
    component: shallowRef(defineAsyncComponent(() => import('../tos/form/TosForm.vue'))),
    titleKey: 'tos.title',
    percentage: 0,
  },
  {
    component: shallowRef(defineAsyncComponent(() => import('../fan-personal-info/FanPersonalInfoForm.vue'))),
    titleKey: 'fanPersonalInfo.title',
    percentage: 20,
  },
  {
    component: shallowRef(defineAsyncComponent(() => import('../due-diligence/DueDiligence.vue'))),
    titleKey: 'dueDiligence.title',
    tooltipKey: 'dueDiligence.tooltip',
    percentage: 40,
  },
  {
    component: shallowRef(defineAsyncComponent(() => import('../pending-due-diligence/PendingDueDiligence.vue'))),
    titleKey: 'pendingDueDiligence.title',
    percentage: 60,
  },
  {
    component: shallowRef(defineAsyncComponent(() => import('../membership-donation/MembershipDonation.vue'))),
    titleKey: 'membershipDonation.title',
    percentage: 80,
  },
  {
    component: shallowRef(defineAsyncComponent(() => import('../kyc-validated/KycValidated.vue'))),
    titleKey: 'kycValidated.title',
    percentage: 100,
  },
];
