include ../../icon/icon.mixin.pug

.fp-presentation-card--image-badge(v-if='auction.status === "UPCOMING"' data-test='upcoming-time-left')
  +fp-icon('clock', {size: '16', color: 'shade-500'})
  .fp-presentation-card--image-badge-text
    .fp-text.-text-xs {{ t('marketplace.auction.startsIn') }}
    code.fp-text.-text-xs.-text-semi-bold {{ countdownBeforeStartDate.daysLeft + t('countdown.shortDays') }} {{ countdownBeforeStartDate.hoursLeft + t('countdown.shortHours') }} {{ countdownBeforeStartDate.minutesLeft + t('countdown.shortMinutes') }} {{ countdownBeforeStartDate.secondsLeft + t('countdown.shortSeconds') }}
.fp-presentation-card--image-badge(v-if='auction.status === "ACTIVE" && auction.bids.length' data-test='active-last-bid' :class='{ "-success": auction.bids[0].bidder.me }')
  .fp-image.-circled.-sm
    FairplayerImageVue(:url='auction.bids[0].bidder.pictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
  .fp-presentation-card--image-badge-text
    .fp-text.-text-xs {{ t('marketplace.auction.currentDonation') }}
    .fp-text.-text-xs.-text-semi-bold {{ auction.bids[0].bidder.firstName }} {{ auction.bids[0].bidder.lastName }}
.fp-presentation-card--image-badge(v-if='auction.status === "ACTIVE" && auction.bids.length === 0' data-test='active-no-bid')
  +fp-icon('close-circle', {size: '16', color: 'shade-500'})
  .fp-presentation-card--image-badge-text
    .fp-text.-text-xs {{ t('marketplace.auction.currentDonation') }}
    .fp-text.-text-xs.-text-semi-bold {{ t('marketplace.auction.noBidder') }}
.fp-presentation-card--image-badge(v-if='auction.status === "CLOSED" && auction.bids.length' data-test='closed-last-bid' :class='{ "-success": auction.bids[0].bidder.me }')
  .fp-image.-circled.-sm
    FairplayerImageVue(:url='auction.bids[0].bidder.pictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
  .fp-presentation-card--image-badge-text
    .fp-text.-text-xs {{ t('marketplace.auction.wonBy') }}
    .fp-text.-text-xs.-text-semi-bold {{ auction.bids[0].bidder.firstName }} {{ auction.bids[0].bidder.lastName }}
.fp-presentation-card--image-badge(v-if='auction.status === "CLOSED" && auction.bids.length === 0' data-test='closed-no-bid')
  +fp-icon('close-circle', {size: '16', color: 'shade-500'})
  .fp-presentation-card--image-badge-text
    .fp-text.-text-xs {{ t('marketplace.auction.auctionClosed') }}
    .fp-text.-text-xs.-text-semi-bold {{ t('marketplace.auction.noBidder') }}
