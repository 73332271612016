.fp-vertical-spacing--line
  .fp-responsive-grid.-s-lg.-cols3.-cols1-up-sm.-cols2-up-md
    .fp-responsive-grid--column(v-for='(post, index) in limitedPosts' :key='index' :data-test='`post-${index}`')
      router-link.fp-vertical-spacing.-s-sm.fp-link.-no-text-decoration(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page')
        .fp-vertical-spacing--line
          .fp-image.-rounded.-expand.-wide.-hoverable
            FairplayerImageVue(:url='post.imageUrl' data-test='image' lazy)
        .fp-vertical-spacing--line
          .fp-text.-text-semi-bold.-lines-2(data-test='name') {{ post.name }}
        .fp-vertical-spacing--line
          .fp-text.-lines-2 {{ post.description.raw }}
        .fp-vertical-spacing--line
          .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}
