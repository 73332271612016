import { InjectionKey } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { BottomModalOpened } from '@/common/domain/bottom-modal/BottomModalOpened';
import { BusCallback } from '@/common/domain/BusCallback';

export const bottomModalBusKey: InjectionKey<BottomModalBus> = Symbol();

export interface BottomModalBus {
  open(modal: BottomModalOpened): void;
  close(): void;
  opened(callback: BusCallback<BottomModalOpened>): Unsubscribe;
  closed(callback: BusCallback<void>): Unsubscribe;
}
