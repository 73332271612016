import { Club } from '@/fairplayer/domain/club/Club';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { fromPartner, PartnerUi } from '@/fairplayer/primary/club/Partner.ui';
import { fromGiveaway, GiveawayUi } from '@/fairplayer/primary/club/Giveaway.ui';
import { ClubSummaryUi, fromClubSummary } from '@/fairplayer/primary/homepage/club-selection/ClubSummary.ui';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';
import { FiatUi, fromFiat } from '@/common/primary/token/FiatUi';
import { renderMarkdown } from '@/common/primary/MarkdownRenderer';
import { MarkdownUi } from '@/common/primary/Markdown.ui';

const VIP_PARTIAL_SLUGS = ['club70'];

export interface ClubUi {
  name: string;
  platformName: string;
  slug: string;
  model: ClubModel;
  logoUrl: string;
  bannerUrl: string;
  contactEmail: string;
  state: ClubState;
  eulaUrl: string;
  sfcUrl: string;
  privacyPolicyUrl: string;
  referralNeeded: boolean;
  isFoundation: boolean;
  isMarketplace: boolean;
  fiatAccepted: boolean;
  coinsEnabled: boolean;
  vip: boolean;
  presentation?: MarkdownUi;
  membershipFee?: FiatUi;
  languages: string[];
  partners: PartnerUi[];
  activeGiveaways: GiveawayUi[];
  linkedClubs: ClubSummaryUi[];
}

export const fromClub = (club: Club): ClubUi => ({
  name: club.name,
  platformName: club.platformName,
  slug: club.slug,
  model: club.model,
  logoUrl: club.logoUrl,
  bannerUrl: club.bannerUrl,
  contactEmail: club.contactEmail,
  state: club.state,
  eulaUrl: club.eulaUrl,
  sfcUrl: club.sfcUrl,
  privacyPolicyUrl: club.privacyPolicyUrl,
  referralNeeded: club.referralNeeded,
  isFoundation: club.model === ClubModel.FOUNDATION,
  isMarketplace: club.model === ClubModel.MARKETPLACE,
  fiatAccepted: club.fiatAccepted,
  coinsEnabled: club.coinsEnabled,
  vip: VIP_PARTIAL_SLUGS.some(slug => club.slug.includes(slug)),
  presentation: club.presentation.map(renderMarkdown).orElseUndefined(),
  membershipFee: club.membershipFee.map(fromFiat).orElseUndefined(),
  languages: club.languages,
  partners: club.partners.map(fromPartner),
  activeGiveaways: club.activeGiveaways.map(fromGiveaway),
  linkedClubs: club.linkedClubs.map(fromClubSummary),
});
