import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusiveId } from '@/fairplayer/domain/exclusive/ExclusiveId';
import { ExclusiveSlug } from '@/fairplayer/domain/exclusive/ExclusiveSlug';
import { InjectionKey } from 'vue';
import { FiatOrderToCreate } from '@/fairplayer/domain/exclusive/FiatOrderToCreate';
import { Bid } from '@/fairplayer/domain/exclusive/Bid';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';

export const exclusiveRepositoryKey: InjectionKey<ExclusiveRepository> = Symbol();

export interface ExclusiveRepository {
  listBySlug(slug: ClubSlug): Promise<Exclusive[]>;
  listRecommended(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug, category: ExclusiveCategory): Promise<Exclusive[]>;
  listDuePayments(clubSlug: ClubSlug): Promise<Exclusive[]>;
  getBySlug(clubSlug: ClubSlug, slug: ExclusiveSlug): Promise<Optional<Exclusive>>;
  createCoinsOrder(slug: ClubSlug, exclusiveId: ExclusiveId): Promise<void>;
  createFiatOrder(order: FiatOrderToCreate): Promise<CheckoutSession>;
  createBid(clubSlug: ClubSlug, exclusiveSlug: ExclusiveSlug, priceCoinsAmount: number): Promise<Bid>;
}
