import { defineAsyncComponent, shallowRef } from 'vue';

export const exclusivesListFiltersBottomModal = () =>
  shallowRef(
    defineAsyncComponent(
      () => import('@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-filter-modal/ExclusivesListFilterModal.vue')
    )
  );

export const confirmPurchaseBottomModal = () =>
  shallowRef(
    defineAsyncComponent(() => import('@/fairplayer/primary/marketplace/exclusive-page/confirm-purchase-modal/ConfirmPurchaseModal.vue'))
  );

export const profilePictureBottomModal = () =>
  shallowRef(defineAsyncComponent(() => import('@/fairplayer/primary/profile/profile-picture-modal/ProfilePictureModal.vue')));
