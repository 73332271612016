import { defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useRoute, useRouter } from 'vue-router';
import { emptyLoginFormUi, LoginFormUi, toCredential } from '@/common/primary/auth/login-page/LoginForm.ui';
import { PasswordVue } from '@/common/primary/auth/password';
import { loggerKey } from '@/common/domain/Logger';
import { AuthState } from '@/common/domain/auth/AuthState';
import { EmailConfirmationVue } from '@/common/primary/auth/email-confirmation';

const OLD_CLUB_SLUGS = ['lou-rugby', 'paris-volley', 'narbonne-volley'];

export default defineComponent({
  name: 'LoginForm',

  components: { FairplayerButtonVue, PasswordVue, EmailConfirmationVue },

  props: {
    isRedirecting: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const authentication = inject(authenticationKey)!;
    const logger = inject(loggerKey)!;

    const loginForm = ref<LoginFormUi>(emptyLoginFormUi());
    const twoFactorNeeded = ref(false);
    const invalidLogin = ref(false);
    const isLogging = ref(false);

    const oldClub = OLD_CLUB_SLUGS.includes(route.params.slug as string);

    onMounted(async () => {
      if (!props.isRedirecting) {
        const authenticated = await authentication.isAuthenticated();
        if (authenticated) {
          await router.replace({ name: 'clubHomepage', params: { slug: route.params.slug as string } });
        }
      }
    });

    const redirectOnAuthenticated = () => async (authState: AuthState) => {
      if (authState && authState.authenticationCompleted) {
        return router.push({ name: 'loginRedirectCallback' });
      }

      await authentication.resendConfirmationCode(loginForm.value.email);
      twoFactorNeeded.value = true;
    };

    const invalidLoginError = () => (error: any) => {
      invalidLogin.value = true;
      logger.error('Failed to login', error);
    };

    const login = async () => {
      invalidLogin.value = false;
      isLogging.value = true;
      authentication
        .loginWithCredential(toCredential(loginForm.value))
        .then(redirectOnAuthenticated())
        .catch(invalidLoginError())
        .finally(() => (isLogging.value = false));
    };

    const googleLogin = async () => await authentication.googleLogin();

    const updatePassword = (password: string) => (loginForm.value.password = password);

    return {
      invalidLogin,
      isLogging,
      loginForm,
      login,
      googleLogin,
      oldClub,
      t,
      route,
      twoFactorNeeded,
      updatePassword,
    };
  },
});
