import { MailToContactVue } from '@/fairplayer/primary/email';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { createTosForm } from '@/fairplayer/primary/tos/Tos.ui';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { LanguageSelectorVue } from '@/fairplayer/primary/page-footer/language-selector';
import { ThemeSelectorVue } from '@/fairplayer/primary/page-footer/theme-selector';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';
import { fanConsentRepositoryKey } from '@/fairplayer/domain/fan/consent/FanConsentRepository';

export default defineComponent({
  name: 'PageFooter',

  components: {
    MailToContactVue,
    LanguageSelectorVue,
    ThemeSelectorVue,
  },

  setup() {
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanConsentRepository = inject(fanConsentRepositoryKey)!;

    const pageFooterCollapsed = ref(false);

    const currentClub = clubRepository.findCurrentClub().map(fromClub);
    const showLanguageSelector = currentClub.map(club => club.languages.length > 1).orElse(false);
    const documents = currentClub.map(createTosForm).orElse([]);
    const isFoundation = currentClub.map(club => club.isFoundation).orElse(false);
    const referralNeeded = currentClub.map(club => club.referralNeeded).orElse(false);

    const clubNameOrFairplayer = currentClub
      .filter(club => club.model === ClubModel.FOUNDATION)
      .map(club => club.name)
      .orElse('Fairplayer');
    const datedMentions = `© 2024 ${clubNameOrFairplayer}`;

    const displayConsentModal = () => fanConsentRepository.showConsentModal();

    return {
      displayConsentModal,
      pageFooterCollapsed,
      isFoundation,
      datedMentions,
      documents,
      referralNeeded,
      staticFilesUri,
      showLanguageSelector,
      t,
    };
  },
});
