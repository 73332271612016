include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    h1.fp-title(data-test='exclusive-name') {{ exclusive.name }}
  template(v-if='isFoundation')
    .fp-vertical-spacing--line
      .fp-alert.-info
        +fp-icon('info', {color: 'info', size: '18'})
        template(v-if='exclusive.auction')
          span.fp-text.-paragraph(v-if='exclusive.auction.status === \'CLOSED\'' data-test='exclusive-closed-auction') {{ t('marketplace.exclusiveDetail.auction.status.closed') }}
          span.fp-text.-paragraph(v-else data-test='exclusive-participate-auction') {{ t('marketplace.exclusiveDetail.auction.participate') }}
        template(v-if='!exclusive.auction')
          span.fp-text.-paragraph(v-if='exclusive.category === \'COLLECTIBLE\'' data-test='collectible-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationCollectible') }}
          span.fp-text.-paragraph(v-else data-test='experience-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationExperience') }}
  .fp-vertical-spacing--line(v-if='!isFoundation')
    span.fp-tag(data-test='exclusive-category')
      i.fp-icon(:class='exclusive.categoryIcon')
      span.fp-tag--text {{ t(`marketplace.categories.${exclusive.category.toLowerCase()}`) }}
  .fp-vertical-spacing--line(v-if='exclusive.auction && exclusive.auction.bids && exclusive.auction.bids.length' data-test='current-price')
    .fp-vertical-spacing.-s-xs
      .fp-vertical-spacing--line
        h2.fp-title(v-if='exclusive.auction.status === \'CLOSED\'' data-test='auction-won-label') {{ t('marketplace.auction.wonBy') }}
        h2.fp-title(v-else data-test='auction-current-bid-label') {{ t('marketplace.exclusiveDetail.auction.currentPrice') }}
      .fp-vertical-spacing--line
        ExclusiveAuctionBidVue(highlighted :bid='exclusive.auction.bids[0]')
  .fp-vertical-spacing--line.-hidden-up-md(v-if='!exclusive.auction' data-test='heading-price')
    ExclusivePriceVue(:pricing='exclusive.pricing' xl)
