import { Member } from '@/fairplayer/domain/member/Member';

export interface BackendMember {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export const toMember = (backendMember: BackendMember): Member => ({
  id: backendMember.id,
  pictureUrl: backendMember.pictureUrl,
  firstName: backendMember.firstName,
  lastName: backendMember.lastName,
  phoneNumber: backendMember.phoneNumber,
});
