import { inject, onMounted, onUnmounted, ref } from 'vue';
import { globalWindowKey } from '@/common/domain/Window';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { BottomModalOpened } from '@/common/domain/bottom-modal/BottomModalOpened';
import { useI18n } from 'vue-i18n';

const BOTTOM_MODAL_CLOSING_DELAY = 400;

export default {
  name: 'BottomModal',

  setup() {
    const { t } = useI18n();
    const bottomModalBus = inject(bottomModalBusKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const bottomModal = ref();
    const isActive = ref(false);
    let unsubscribeOpenModal: Unsubscribe;
    let unsubscribeCloseModal: Unsubscribe;

    const closeOnEscape = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        bottomModalBus.close();
      }
    };

    onMounted(async () => {
      globalWindow.document.addEventListener('keyup', closeOnEscape);
      unsubscribeOpenModal = bottomModalBus.opened(openBottomModal);
      unsubscribeCloseModal = bottomModalBus.closed(closeBottomModal);
    });

    onUnmounted(() => {
      globalWindow.document.removeEventListener('keyup', closeOnEscape);
      unsubscribeOpenModal();
      unsubscribeCloseModal();
    });

    const openBottomModal = (bottomModalOpened: BottomModalOpened) => {
      bottomModal.value = bottomModalOpened;
    };

    const delaySetActiveForSlideInAnimation = () => {
      globalWindow.setTimeout(() => (isActive.value = true), 1);
    };

    const setInactive = () => {
      isActive.value = false;
    };

    const closeBottomModal = () => {
      setInactive();
      globalWindow.setTimeout(() => (bottomModal.value = null), BOTTOM_MODAL_CLOSING_DELAY);
    };

    const stopPropagation = (event: MouseEvent) => {
      event.stopPropagation();
    };

    return {
      t,
      isActive,
      bottomModal,
      openBottomModal,
      closeBottomModal,
      stopPropagation,
      delaySetActiveForSlideInAnimation,
    };
  },
};
