import { fromMessage, MessageUi } from '@/fairplayer/primary/Message.ui';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';
import { FanId } from '@/common/domain/FanId';
import { DatedMessagesUi, fromMessagesUi } from '@/fairplayer/primary/DatedMessages.ui';
import { MemberUi } from '@/fairplayer/primary/members-page/Member.ui';

export interface InterlocutorMessagesUi {
  interlocutorId: string;
  interlocutorPictureUrl: string;
  interlocutorFirstName: string;
  interlocutorLastName: string;
  datedMessages: DatedMessagesUi[];
  numberOfUnreadMessages: number;
}

export const fromInterlocutorMessages = (
  senderId: FanId,
  interlocutor: MemberUi,
  locale: string,
  interlocutorMessages?: InterlocutorMessages
): InterlocutorMessagesUi => ({
  interlocutorId: interlocutor.id,
  interlocutorPictureUrl: interlocutor.pictureUrl,
  interlocutorFirstName: interlocutor.firstName,
  interlocutorLastName: interlocutor.lastName,
  datedMessages: fromMessagesUi(
    interlocutorMessages?.messages.map(message => fromMessage(message, senderId === message.senderId)) ?? [],
    locale
  ),
  numberOfUnreadMessages: interlocutorMessages?.numberOfUnreadMessages ?? 0,
});

export const pushMessage = (message: MessageUi, interlocutorMessages: InterlocutorMessagesUi, locale: string): InterlocutorMessagesUi => ({
  interlocutorId: interlocutorMessages.interlocutorId,
  interlocutorPictureUrl: interlocutorMessages.interlocutorPictureUrl,
  interlocutorFirstName: interlocutorMessages.interlocutorFirstName,
  interlocutorLastName: interlocutorMessages.interlocutorLastName,
  datedMessages: pushMessageToDate(message, interlocutorMessages.datedMessages, locale),
  numberOfUnreadMessages: interlocutorMessages.numberOfUnreadMessages,
});

const pushMessageToDate = (message: MessageUi, datedMessages: DatedMessagesUi[], locale: string): DatedMessagesUi[] => {
  const date = message.date.toLocaleDateString(locale, { day: 'numeric', month: 'long' });

  const existingDatedMessage = datedMessages.find(datedMessage => datedMessage.date === date);

  if (existingDatedMessage) {
    existingDatedMessage.messages.push(message);
  } else {
    datedMessages.push({
      date,
      messages: [message],
    });
  }
  return datedMessages;
};
