import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { globalWindowKey } from '@/common/domain/Window';
import { mobileWindowCheckerKey } from '@/common/primary/breakpoint/MobileWindowChecker';
import { mobileGestureKey } from '@/common/primary/gesture/MobileGesture';
import { defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { PresentationCardVue } from '@/common/primary/presentation-card';

const TIMEOUT_DELAY = 3000;

const CAROUSEL_IMAGES_MOBILE = 2;
const CAROUSEL_IMAGES_DESKTOP = 4;

export default defineComponent({
  name: 'Carousel',

  components: { PresentationCardVue },

  props: {
    total: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  emits: ['index-updated'],

  setup(props, { emit }) {
    const globalWindow = inject(globalWindowKey)!;
    const mobileGesture = inject(mobileGestureKey)!;
    const mobileWindowChecker = inject(mobileWindowCheckerKey)!;

    const currentIndex = ref(0);
    const timeoutId = ref(0);

    let unsubscribeLeftSwipe: Unsubscribe;
    let unsubscribeRightSwipe: Unsubscribe;

    const goToPage = (page: number) => {
      currentIndex.value = page;
      emit('index-updated', { value: page });
    };

    const onTouchStart = (event: TouchEvent) => {
      mobileGesture.touchStart(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
    };

    const onTouchEnd = (event: TouchEvent) => {
      mobileGesture.touchEnd(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
    };

    onMounted(() => {
      unsubscribeLeftSwipe = mobileGesture.onLeftSwipe(goToNextPage);
      unsubscribeRightSwipe = mobileGesture.onRightSwipe(goToPreviousPage);
    });

    onUnmounted(() => {
      unsubscribeLeftSwipe();
      unsubscribeRightSwipe();
    });

    const lastElementToBeActive = () => {
      if (mobileWindowChecker.isMobile()) {
        return props.total - CAROUSEL_IMAGES_MOBILE;
      }
      return props.total - CAROUSEL_IMAGES_DESKTOP;
    };

    const goToPreviousPage = () => {
      if (currentIndex.value === 0) {
        goToPage(lastElementToBeActive());
      } else {
        goToPage(currentIndex.value - 1);
      }
      delayGoToNextPage();
    };

    const goToNextPage = () => {
      if (currentIndex.value >= lastElementToBeActive()) {
        goToPage(0);
      } else {
        goToPage(currentIndex.value + 1);
      }
      delayGoToNextPage();
    };

    const enter = () => {
      globalWindow.clearTimeout(timeoutId.value);
    };

    const leave = () => {
      delayGoToNextPage();
    };

    const delayGoToNextPage = () => {
      globalWindow.clearTimeout(timeoutId.value);
      timeoutId.value = globalWindow.setTimeout(() => goToNextPage(), TIMEOUT_DELAY);
    };
    delayGoToNextPage();

    return {
      currentIndex,
      enter,
      leave,
      onTouchStart,
      onTouchEnd,
    };
  },
});
