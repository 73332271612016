import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, nextTick, onMounted, PropType, Ref, ref, useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { messageRepositoryKey } from '@/fairplayer/domain/message/MessageRepository';
import { fromInterlocutorMessages, InterlocutorMessagesUi, pushMessage } from '@/fairplayer/primary/InterlocutorMessages.ui';
import { useRouter } from 'vue-router';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { fromMessage } from '@/fairplayer/primary/Message.ui';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { fromMember, MemberUi } from '@/fairplayer/primary/members-page/Member.ui';

export default defineComponent({
  name: 'ChatPage',

  components: {
    PageVue,
    FairplayerImageVue,
  },

  props: {
    memberId: {
      type: String as PropType<string>,
      required: true,
    },
    membersList: {
      type: Array as PropType<MemberUi[]>,
      required: true,
    },
  },

  emits: ['updated-conversation'],

  setup(props, { emit }) {
    const clubRepository = inject(clubRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const messageRepository = inject(messageRepositoryKey)!;

    const { t } = useI18n();
    const router = useRouter();

    const clubSlug = clubRepository.getCurrentSlug();
    const conversation = ref(Loader.loading<InterlocutorMessagesUi>());
    const chatMessages: Ref<any> = useTemplateRef('chat-messages');
    const message = ref('');
    const isSending = ref(false);

    const fanLanguage = fanLanguageRepository.getCurrentLanguage();

    const canSendMessage = computed(() => !isSending.value && message.value.trim().length > 0);

    onMounted(async () => await loadConversation());

    const onConversationLoaded = async (messages: InterlocutorMessagesUi) => {
      conversation.value.loaded(messages);
      await nextTick();
      chatMessages.value.scrollTop = chatMessages.value.scrollHeight;
    };

    const sendMessage = async (): Promise<void> => {
      if (canSendMessage.value) {
        isSending.value = true;
        messageRepository
          .sendForClub(clubSlug, {
            receiverId: props.memberId,
            text: message.value,
          })
          .then(messageSent => {
            message.value = '';
            onConversationLoaded(pushMessage(fromMessage(messageSent, false), conversation.value.value(), fanLanguage));
            emit('updated-conversation');
          })
          .finally(() => (isSending.value = false));
      }
    };

    const loadConversation = async (): Promise<void> => {
      try {
        const interlocutor = props.membersList.find(member => member.id === props.memberId)!;
        const interlocutorMessages = await messageRepository.getForClub(clubSlug);
        const messages = interlocutorMessages.find(message => message.interlocutorId === props.memberId);
        await onConversationLoaded(fromInterlocutorMessages(props.memberId, fromMember(interlocutor, false), fanLanguage, messages));
        messageRepository.readConversation(clubSlug, props.memberId).then(() => emit('updated-conversation'));
      } catch (error: any) {
        logger.error('Failed to retrieve members', error);
        await router.push({ name: 'membersList' });
      }
    };

    return {
      chatMessages,
      canSendMessage,
      isSending,
      FairplayerFallbackImageUi,
      message,
      conversation,
      sendMessage,
      t,
    };
  },
});
