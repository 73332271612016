import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { RouteRecordRaw } from 'vue-router';
import { ClubLayoutVue } from '@/fairplayer/primary/club-layout';
import { ClubHomepageVue } from '@/fairplayer/primary/home/club-homepage';
import { RouterTab } from '@/router/RouterTab';
import { LoginPageVue } from '@/common/primary/auth/login-page';
import { SignupVue } from '@/common/primary/auth/signup';
import { ForgotPasswordVue } from '@/common/primary/auth/forgot-password';
import { WalletPageVue } from '@/fairplayer/primary/wallet';
import { NewsfeedVue } from '@/fairplayer/primary/newsfeed';
import { PostPageVue } from '@/fairplayer/primary/newsfeed/post-page';
import { ProgramsListVue } from '@/fairplayer/primary/programs/programs-list';
import { ProgramPageVue } from '@/fairplayer/primary/programs/program-page';
import { ExclusivesListVue } from '@/fairplayer/primary/marketplace/exclusives-list';
import { ExclusivePageVue } from '@/fairplayer/primary/marketplace/exclusive-page';
import { PurchaseConfirmationVue } from '@/fairplayer/primary/marketplace/purchase-confirmation';
import { PurchaseConfirmedVue } from '@/fairplayer/primary/marketplace/purchase-confirmed';
import { ProfileVue } from '@/fairplayer/primary/profile';
import { KycFormVue } from '@/fairplayer/primary/kyc-form';
import { GiveawayParticipationPageVue } from '@/fairplayer/primary/giveaway-participation';
import { NotFoundVue } from '@/common/primary/not-found';
import { HomepageVue } from '@/fairplayer/primary/homepage';
import { ClubsPageVue } from '@/fairplayer/primary/clubs-page';
import { LoginRedirectCallbackVue } from '@/fairplayer/primary/auth/login-redirect-callback';
import { MembersPageVue } from '@/fairplayer/primary/members-page';
import { MembersListVue } from '@/fairplayer/primary/members-page/members-list';
import { ChatPageVue } from '@/fairplayer/primary/members-page/chat-page';
import { DonationPageVue } from '@/fairplayer/primary/donation-page';
import { OneTimeDonationPaymentConfirmedVue } from '@/fairplayer/primary/donation-page/payment-confirmed';

export const CLUB_HOMEPAGE_ROUTE_NAME = 'clubHomepage';

const routesDependingOnCustomDomain = (clubRepository: ClubRepository) => {
  if (clubRepository.isCustomClubDomain()) {
    return [];
  }

  return [
    {
      path: '/',
      name: 'homepage',
      component: HomepageVue,
    },
    {
      path: '/clubs',
      name: 'clubsPage',
      component: ClubsPageVue,
    },
  ];
};

const computeClubLayoutPath = (clubRepository: ClubRepository) => {
  if (clubRepository.isCustomClubDomain()) {
    return '/';
  }

  return '/clubs/:slug';
};

export const computeRoutes = (clubRepository: ClubRepository): Readonly<RouteRecordRaw[]> => {
  return [
    ...routesDependingOnCustomDomain(clubRepository),
    {
      path: '/login/callback',
      name: 'loginRedirectCallback',
      component: LoginRedirectCallbackVue,
    },
    {
      path: computeClubLayoutPath(clubRepository),
      component: ClubLayoutVue,
      children: [
        {
          path: '',
          name: CLUB_HOMEPAGE_ROUTE_NAME,
          component: ClubHomepageVue,
          meta: {
            tabs: [RouterTab.HOMEPAGE],
          },
        },
        {
          path: 'login',
          name: 'login',
          component: LoginPageVue,
          meta: {
            tabs: [RouterTab.LOGIN],
          },
        },
        {
          path: 'signup',
          name: 'signup',
          component: SignupVue,
          meta: {
            tabs: [RouterTab.LOGIN],
          },
        },
        {
          path: 'forgot-password',
          name: 'forgotPassword',
          component: ForgotPasswordVue,
          meta: {
            tabs: [RouterTab.LOGIN],
          },
        },
        {
          path: 'wallet',
          name: 'wallet',
          component: WalletPageVue,
          meta: {
            tabs: [RouterTab.WALLET],
          },
        },
        {
          path: 'members',
          name: 'members',
          component: MembersPageVue,
          meta: {
            tabs: [RouterTab.MEMBERS],
            requireAuthentication: true,
          },
          children: [
            {
              path: '',
              name: 'membersList',
              component: MembersListVue,
            },
            {
              path: ':memberId',
              name: 'chatPage',
              component: ChatPageVue,
              props: true,
            },
          ],
        },
        {
          path: 'donation',
          name: 'donationPage',
          component: DonationPageVue,
          meta: {
            tabs: [RouterTab.DONATIONS],
          },
        },
        {
          path: 'donation/payment-confirmed',
          name: 'oneTimeDonationPaymentConfirmed',
          component: OneTimeDonationPaymentConfirmedVue,
          meta: {
            tabs: [RouterTab.DONATIONS],
          },
        },
        {
          path: 'news',
          name: 'newsfeed',
          component: NewsfeedVue,
          meta: {
            tabs: [RouterTab.NEWS],
          },
        },
        {
          path: 'news/:postSlug',
          name: 'postPage',
          component: PostPageVue,
          meta: {
            tabs: [RouterTab.NEWS],
          },
        },
        {
          path: 'programs',
          name: 'programs',
          component: ProgramsListVue,
          meta: {
            tabs: [RouterTab.PROGRAMS],
          },
        },
        {
          path: 'programs/:programSlug',
          name: 'programPage',
          component: ProgramPageVue,
          meta: {
            tabs: [RouterTab.PROGRAMS],
          },
        },
        {
          path: 'marketplace',
          name: 'marketplace',
          component: ExclusivesListVue,
          meta: {
            tabs: [RouterTab.MARKETPLACE],
          },
        },
        {
          path: 'exclusives/:exclusiveSlug',
          name: 'exclusiveDetail',
          component: ExclusivePageVue,
          meta: {
            tabs: [RouterTab.MARKETPLACE, RouterTab.DONATIONS],
          },
        },
        {
          path: 'exclusives/:exclusiveSlug/purchase-confirmation',
          name: 'purchaseConfirmation',
          component: PurchaseConfirmationVue,
          meta: {
            tabs: [RouterTab.MARKETPLACE],
            requireAuthentication: true,
          },
        },
        {
          path: 'exclusives/:exclusiveSlug/purchase-confirmed',
          name: 'purchaseConfirmed',
          component: PurchaseConfirmedVue,
          meta: {
            tabs: [RouterTab.MARKETPLACE],
            requireAuthentication: true,
          },
        },
        {
          path: 'profile',
          name: 'profile',
          component: ProfileVue,
          meta: {
            tabs: [RouterTab.PROFILE],
            requireAuthentication: true,
          },
        },
        {
          path: 'kyc',
          name: 'kyc',
          component: KycFormVue,
          meta: {
            requireAuthentication: true,
          },
        },
        {
          path: 'giveaway',
          name: 'giveaway',
          component: GiveawayParticipationPageVue,
        },
        {
          path: ':pathMatch(.*)*',
          name: 'notFound',
          component: NotFoundVue,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'rootNotFound',
      component: NotFoundVue,
    },
  ];
};
