import { useI18n } from 'vue-i18n';
import { computed, defineComponent, PropType } from 'vue';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { AuctionStatus } from '@/fairplayer/domain/exclusive/AuctionStatus';

export default defineComponent({
  name: 'LostAuctions',

  components: {
    FairplayerImageVue,
  },

  props: {
    exclusives: {
      type: Array as PropType<ExclusiveUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const lostAuctions = computed(() => {
      const auctions = props.exclusives.filter(e => e.auction !== undefined && e.auction.status === AuctionStatus.ACTIVE);
      const auctionsWithBids = auctions.filter(e => e.auction!.bids.length > 0);
      const auctionsParticipated = auctionsWithBids.filter(e => e.auction!.bids.some(b => b.bidder.me));
      return auctionsParticipated.filter(e => !e.auction!.bids[0].bidder.me);
    });

    return {
      t,
      lostAuctions,
    };
  },
});
