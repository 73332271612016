import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { orderRepositoryKey } from '@/fairplayer/domain/fan/order/OrderRepository';
import { OrderListVue } from '@/fairplayer/primary/order-list';
import { fromOrder, OrderUi } from '@/fairplayer/primary/order-list/Order.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LogoutVue } from '@/common/primary/auth/logout';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { DonationListVue } from '@/fairplayer/primary/profile/donation-list';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { DonationUi, fromDonation } from '@/fairplayer/primary/profile/donation-list/Donation.ui';
import { ReferralListVue } from '@/fairplayer/primary/profile/referral-list';
import { profilePictureBottomModal } from '@/common/primary/bottom-modal/BottomModals';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ExclusivePaymentDueListVue } from '@/fairplayer/primary/profile/exclusive-payment-due-list';

export default {
  name: 'Profile',

  components: {
    DonationListVue,
    ExclusivePaymentDueListVue,
    FairplayerImageVue,
    FairplayerButtonVue,
    OrderListVue,
    ReferralListVue,
    PageVue,
    LogoutVue,
  },

  setup() {
    const { t } = useI18n();

    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const orderRepository = inject(orderRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const fanLanguage = fanLanguageRepository.getCurrentLanguage();
    const isMarketplace = fromClub(club).isMarketplace;
    const isFoundation = fromClub(club).isFoundation;

    const donationList = ref(Loader.loading<DonationUi[]>());
    const exclusivePaymentDueList = ref(Loader.loading<ExclusiveUi[]>());
    const fan = ref(Loader.loading<Fan>());
    const orderList = ref(Loader.loading<OrderUi[]>());

    onMounted(async () => {
      if (isMarketplace) {
        await Promise.all([retrieveFan(), retrieveOrders()]);
      }
      if (isFoundation) {
        await Promise.all([retrieveFan(), retrieveOrders(), retrieveDonations(), retrieveDuePayments()]);
      }
    });

    const retrieveFan = async (): Promise<void> => fanRepository.getForClub(club, true).then(fanLoaded => fan.value.loaded(fanLoaded));

    const retrieveDuePayments = async () =>
      await exclusiveRepository.listDuePayments(club.slug).then(exclusives => {
        exclusivePaymentDueList.value.loaded(exclusives.map(e => fromExclusive(e, club)));
      });

    const retrieveOrders = async () => {
      orderList.value.loaded(
        await orderRepository.listFor(clubRepository.getCurrentSlug()).then(orders => orders.map(order => fromOrder(order, fanLanguage)))
      );
    };

    const retrieveDonations = async () => {
      donationList.value.loaded(
        await donationRepository
          .listFor(clubRepository.getCurrentSlug())
          .then(donations => donations.map(donation => fromDonation(donation, fanLanguage)))
      );
    };

    const onProfilePictureUpdate = async (): Promise<void> =>
      await retrieveFan().then(() => {
        bottomModalBus.close();
      });

    const openProfilePictureModal = async () => {
      const modal = profilePictureBottomModal();
      bottomModalBus.open({
        component: modal,
        titleKey: 'profile.profilePictureModal.title',
        icon: 'user',
        options: { fan: fan.value.value(), updated: onProfilePictureUpdate },
      });
    };

    return {
      club,
      donationList,
      exclusivePaymentDueList,
      FairplayerFallbackImageUi,
      fan,
      orderList,
      isMarketplace,
      isFoundation,
      openProfilePictureModal,
      onProfilePictureUpdate,
      t,
    };
  },
};
