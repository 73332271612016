import { FairplayerButtonVue } from '@/common/primary/button';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { ExclusivesFilters, SortBy, SortByOrder, SortOrder } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';
import { defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { filtersToSave } from '@/fairplayer/primary/marketplace/exclusives-list/handleFilters';
import { useRouter } from 'vue-router';

interface ExclusivesListFiltersOptions {
  filters: ExclusivesFilters;
}

export default defineComponent({
  name: 'ExclusivesListFilterModal',

  components: { FairplayerButtonVue },

  props: {
    options: {
      type: Object as PropType<ExclusivesListFiltersOptions>,
      required: true,
    },
  },

  emits: ['ready'],

  setup(props, { emit }: any) {
    const { t } = useI18n();
    const bottomModalBus = inject(bottomModalBusKey)!;
    const router = useRouter();

    const isExperienceChecked = ref(true);
    const isCollectibleChecked = ref(true);
    const sortByOrder = ref<SortByOrder>('price:desc');

    watch(
      () => [props.options.filters],
      () => initFields()
    );

    const initFields = () => {
      isExperienceChecked.value = props.options.filters.categories.includes(ExclusiveCategory.EXPERIENCE);
      isCollectibleChecked.value = props.options.filters.categories.includes(ExclusiveCategory.COLLECTIBLE);
      sortByOrder.value = buildSortByOrder();
    };

    const buildSortByOrder = (): SortByOrder => {
      return (props.options.filters.sortBy + ':' + props.options.filters.sortOrder) as SortByOrder;
    };

    const splitSortByOrder = (): { sortOrder: SortOrder; sortBy: SortBy } => {
      return {
        sortOrder: sortByOrder.value.split(':')[1] as SortOrder,
        sortBy: sortByOrder.value.split(':')[0] as SortBy,
      };
    };

    const updateFilters = () => {
      const categories = [
        { key: ExclusiveCategory.EXPERIENCE, value: isExperienceChecked.value },
        { key: ExclusiveCategory.COLLECTIBLE, value: isCollectibleChecked.value },
      ]
        .filter(category => category.value)
        .map(category => category.key);
      const { sortOrder, sortBy } = splitSortByOrder();
      const filters: ExclusivesFilters = {
        categories,
        sortOrder,
        sortBy,
      };
      const query = filtersToSave(filters);
      router.push({
        query,
      });
      closeFilters();
    };
    const closeFilters = () => {
      bottomModalBus.close();
    };

    onMounted(() => {
      emit('ready');
      initFields();
    });

    return {
      isExperienceChecked,
      isCollectibleChecked,
      t,
      sortByOrder,
      closeFilters,
      updateFilters,
    };
  },
});
