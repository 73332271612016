import { defineComponent, PropType } from 'vue';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { PresentationCardAuctionVue } from '@/common/primary/presentation-card/presentation-card-auction';

export default defineComponent({
  name: 'PresentationCard',

  components: { FairplayerImageVue, ExclusivePriceVue, PresentationCardAuctionVue },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup(props) {
    const { t, d } = useI18n();

    const maskText = () => {
      if (props.exclusive.isUpcoming && props.exclusive.upcomingDate) {
        return t('marketplace.upcomingExclusiveWithDate', {
          date: d(props.exclusive.upcomingDate.toDate(), { month: 'long', day: 'numeric' }),
        });
      }
      if (props.exclusive.isUpcoming && !props.exclusive.upcomingDate) {
        return t('marketplace.upcomingExclusive');
      }
      if (!props.exclusive.visible) {
        return t('marketplace.hiddenExclusive');
      }
      if (props.exclusive.isOutOfStock) {
        return t('marketplace.outOfStock');
      }
      return undefined;
    };

    return {
      t,
      maskText: maskText(),
    };
  },
});
