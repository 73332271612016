include ../../../../common/primary/icon/icon.mixin.pug

.fp-icon-with-text
  template(v-if='hideFiatPrice')
    img.fp-illustration.-xs.-token(alt='Token')
  .fp-icon-with-text--text
    .fp-icon-with-text--text-line(v-if='!hideFiatPrice')
      span.fp-text.-text-semi-bold(:class='{"-text-xl": xl, "-text-lg": lg}' data-test='fiat-price') {{ pricing.totalCost.text }}
      br(v-if='vertical')
      template(v-if='!hideCoinsPrice')
        span.fp-text.-color-shade-500(v-if='!vertical')  /
        span.fp-text.-color-shade-500.-no-wrap(data-test='coin-amount') &nbsp;{{ pricing.coins.text }}
    .fp-icon-with-text--text-line(v-if='hideFiatPrice')
      span.fp-text.-text-semi-bold(:class='{"-text-xl": xl, "-text-lg": lg}' data-test='coin-amount') {{ pricing.coins.text }}
