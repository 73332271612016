import { Bid } from '@/fairplayer/domain/exclusive/Bid';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { fromMember, MemberUi } from '@/fairplayer/primary/members-page/Member.ui';

export interface BidUi {
  id: string;
  bidder: MemberUi;
  price: TokensUi;
  date: Date;
}

export const fromBid = (bid: Bid, me: boolean): BidUi => ({
  id: bid.id,
  bidder: fromMember(bid.bidder, me),
  price: fromTokens(bid.price),
  date: bid.date,
});
